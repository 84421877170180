export const TopicsList = [
  'arbitrum',
  'base',
  'boredApeYachtClub',
  'compound',
  'cryptopunks',
  'ethDenver',
  'ethereum',
  'gnosisSafe',
  'harmony',
  'metamask',
  'opensea',
  'optimism',
  'polygon',
  'sporkDao',
  'uniswap',
  'zkSync'
]
